<template>
  <v-container fluid>
    <div v-if="patentList.length > 0">
      <v-list
        v-for="(patentEntity, index) in patentList"
        :key="index"
        color="#ECF0F3"
      >
        <v-row>
          <v-col>
            <v-card flat width="1400px">
              <v-row justify="space-around">
                <v-col> </v-col>
                <v-spacer> </v-spacer>
                <v-col cols="2">
                  <EditPatent :id="patentEntity.id" />
                </v-col>
              </v-row>
              <v-row class="my-4">
                <v-col cols="3">
                  <div class="text-h7 font-weight-bold mx-3 my-n7 primary--text">
                    Patent Name
                  </div>
                </v-col>
                <v-col cols="9">
                  <div class="text-h7 font-weight-bold  my-n7">
                    {{ patentEntity.patentName }}
                  </div>
                </v-col>
              </v-row>
              <v-row class="my-4"> 
                <v-col cols="3">
                  <div class="text-h7 font-weight-bold mx-3 my-n5 primary--text">
                    Year Granted
                  </div>
                </v-col>
                <v-col cols="9">
                  <div class="text-h7 font-weight-bold  my-n5">
                    {{ patentEntity.yearGranted }}
                  </div>
                </v-col>
              </v-row>
              <v-row class="my-4">
                <v-col cols="3">
                  <div class="text-h7 font-weight-bold mx-3 my-n3 primary--text">
                    Patent Number 
                  </div>
                </v-col>
                <v-col cols="9">
                  <div class="text-h7 font-weight-bold  my-n3">
                    {{ patentEntity.patentNumber }}
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="6">
            <!-- <v-card flat height="150px">
              <v-row justify="space-around">
                <div class="text-h7 font-weight-bold my-3">
                  Verified Checkbox
                </div>
                <v-checkbox
                  color="blue"
                  value="isVerfied"
                  hide-details
                ></v-checkbox>
              </v-row>
            </v-card> -->
          </v-col>
        </v-row>
      </v-list>
    </div>
    <!-- <v-card v-else>
    <h1>No Patent associated with trainer</h1>
  </v-card> -->
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import EditPatent from "../PatentDailog/PatentDailog.vue";
export default {
  name: "PatentCard",
  data() {
    return {
    };
  },
  components: {
    EditPatent
  },
  computed: {
    ...mapState("trainerProfile", {
      loading: "loading",
      profile: "profile",
      patentList: "patentList"
    })
  },
  created() {
    this.$store.dispatch("trainerProfile/getAllPatents",this.profile.trainerId)
  }
};
</script>

<style></style>
