<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="1000px">
      <template v-slot:activator="{ on }">
        <!-- <v-btn color="primary" dark >  -->
        <v-icon color="primary" v-on="on"  v-if="id" @click="getPatent">
          border_color
        </v-icon>
        <v-icon color="primary" v-on="on" large v-else>
          add
        </v-icon>
        
        <!-- </v-btn> -->
      </template>
      <v-card>
        <v-card-title>
          <v-row>
            <v-col cols="10">
              <span class="text-h5 font-weight-bold" v-if="id">Edit patents</span>
              <span class="text-h5 font-weight-bold" v-else>Add patents</span>
            </v-col>
            <v-col cols="1">
              <v-icon color="red" @click="closeForm" dark large dense>
              cancel
            </v-icon>
            </v-col>
            <v-col cols="1">
               <v-icon
              color="green"
              @click="createPatent"
              v-if="!id"
              dark
              large
              dense
            >
              done
            </v-icon>
            <v-icon color="blue" v-else @click="updatePatent" dark large dense>
              done
            </v-icon>
            </v-col>
          </v-row>
          
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form>
            <v-container fluid>
              <v-row>
                <div class="text-h6 font-weight-bold mx-2">
                  Patent Name<span class="red--text">*</span>
                </div>
              </v-row>
              <v-row>
                <v-text-field
                  placeholder="Type here . . . . "
                  outlined
                  v-model="patent.patentName"
                  solo
                ></v-text-field>
              </v-row>
              <v-row>
                <div class="text-h6 font-weight-bold mx-2">
                  Patent Number <span class="red--text">*</span>
                </div>
              </v-row>
              <v-row>
                <v-text-field
                  placeholder="Type here . . . . "
                  outlined
                  solo
                  v-model="patent.patentNumber"
                ></v-text-field>
              </v-row>
              <v-row>
                <div class="text-h6 font-weight-bold mx-2">
                  Patent Issue Year
                </div>
              </v-row>
              <v-row>
                <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="patent.yearGranted"
                    label=""
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="patent.yearGranted"
                  @input="menu = false"
                  type="month"
                ></v-date-picker>
              </v-menu>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState } from "vuex";
export default {
  data: () => ({
    dialog: false,
    menu: false,
    menu1:false
  }),
  computed: {
    ...mapState("trainerProfile", {
      loading: "loading",
      profile: "profile",
      patent:'patent'
    })
  },
  props: ["id"],
  methods:{
    cb(){
      this.dialog=false
    },
    closeForm(){
      this.dialog=false
    },
    updatePatent(){
      this.$store.dispatch('trainerProfile/updatePatent',{patent:this.patent, cb:this.cb })
    },
    getPatent(){
      this.$store.dispatch('trainerProfile/getAPatent',this.id)
    },
    createPatent(){
      let updatedPatent =this.patent
      updatedPatent.trainerId=this.profile.trainerId
      this.$store.dispatch('trainerProfile/createPatent',{ patent:updatedPatent, cb:this.cb })
    }
  }
};
</script>
<style>

.v-text-field--outlined >>> fieldset {
  border-color: rgba(38, 123, 250, 0.986);
  margin-top: 2px;
}
</style>
