<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="1000px">
      <template v-slot:activator="{ on }">
        <v-icon color="primary" v-on="on"  v-if="id"  @click="getPublication">
          border_color
        </v-icon>
        <v-icon color="primary" v-on="on" large v-else>
          add
        </v-icon>
      </template>
      <v-card>
        <v-row>
          <v-col cols="10">
             <v-card-title>
          <span class="text-h5 font-weight-bold mx-3"  v-if="id">Edit Publications</span>
            <span class="text-h5 font-weight-bold mx-3" v-else>Add Publications</span>
        </v-card-title>
          </v-col>
          <v-col cols="1">
            <v-icon color="red" @click="closeForm" dark large dense>
              cancel
            </v-icon>
          </v-col>
          <v-col>
            <v-icon
              color="green"
              @click="createPublication"
              v-if="!id"
              dark
              large
              dense
            >
              done
            </v-icon>
            <v-icon color="blue" v-else @click="updatePublication" dark large dense>
              done
            </v-icon>
          </v-col>
        </v-row>
       
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="publicaitionUpload">
              <v-row>
                <div class="text-h6 font-weight-bold mx-2">
                  Publication Title<span class="red--text">*</span>
                </div>
              </v-row>
              <v-row>
                <v-text-field
                  placeholder="Type here . . . . "
                  outlined
                  v-model="publication.publicationTitle"
                  solo
                ></v-text-field>
              </v-row>
              <v-row>
                <div class="text-h6 font-weight-bold mx-2">
                  Publisher Name <span class="red--text">*</span>
                </div>
              </v-row>
              <v-row>
                <v-text-field
                  placeholder="Type here . . . . "
                  outlined
                  solo
                  v-model="publication.publishedName"
                ></v-text-field>
              </v-row>
              <v-row>
                <div class="text-h6 font-weight-bold mx-2">
                  Publication Type <span class="red--text">*</span>
                </div>
              </v-row>
              <v-row>
                <v-autocomplete clearable outlined
                :items="publicationType"
                v-model="publication.publicationType"
                ></v-autocomplete>
              </v-row>
              <v-row>
                <div class="text-h6 font-weight-bold mx-2">
                  Publication Date
                </div>
              </v-row>
              <v-row>
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="publication.publicationDate"
                      label=""
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                    ></v-text-field>
                  </template>
                  <v-date-picker
                  type="month"
                    v-model="publication.publicationDate"
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState } from "vuex";
export default {
  data: () => ({
    dialog: false,
    menu: false,
    publicationType: [
      "Book",
      "Journal",
      "Conference",
      "Newspaper Article",
      "Magazine Article"
    ]
  }),
  computed: {
    ...mapState("trainerProfile", {
      loading: "loading",
      profile: "profile",
      publication: "publication"
    })
  },
  props: ["id"],
  methods: {
    cb() {
      this.dialog = false;
      this.$refs.publicaitionUpload.reset()
    },
    closeForm(){
      this.dialog=false;
    },
    createPublication() {
        let updatedPublication =this.publication
        updatedPublication.trainerId=this.profile.trainerId
      this.$store.dispatch('trainerProfile/createPublication',{ publication:updatedPublication, cb:this.cb })
    },
    getPublication(){
       this.$store.dispatch('trainerProfile/getAPublicaiton',this.id)
    },
    updatePublication(){
      this.$store.dispatch('trainerProfile/updatePublication',{ publication:this.publication, cb:this.cb })
    }
  }
};
</script>
<style>

.v-text-field--outlined >>> fieldset {
  border-color: rgba(38, 123, 250, 0.986);
  margin-top: 2px;
}
</style>
