<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="1000px">
      <template v-slot:activator="{ on }">
        <v-icon color="primary" v-if="id" dark v-on="on" @click="getCertificate">
          border_color
        </v-icon>
        <v-icon color="primary" dark v-on="on" large v-else>
          add
        </v-icon> 
      </template>
      <v-card>
        <v-card-title>
        <v-row>
          <v-col cols="10">
            <span class="text-h5 font-weight-bold" v-if="id">Edit certificates</span>
            <span class="text-h5 font-weight-bold" v-else>Add certificates</span>
          </v-col>
          <v-col cols="1">
            <v-icon color="red" @click="closeForm" dark large dense>
              cancel
            </v-icon>
          </v-col>
          <v-col cols="1">
              <v-icon
              color="green"
              @click="createCertification"
              v-if="!id"
              dark
              large
              dense
            >
              done
            </v-icon>
            <v-icon color="blue" v-else @click="updateCertificate" dark large dense>
              done
            </v-icon>
          </v-col>
        </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="certificationFrom">
            <v-container>
              <v-row>
                <div class="text-h6 font-weight-bold mx-2">
                  Certificate Name <span class="red--text">*</span>
                </div>
              </v-row>
              <v-row>
                <v-text-field
                  placeholder="Type here . . . . "
                  outlined
                  v-model="certification.certificationName"
                  solo
                ></v-text-field>
              </v-row>
              <v-row>
                <div class="text-h6 font-weight-bold mx-2">
                  Certification issuer <span class="red--text">*</span>
                </div>
              </v-row>
              <v-row>
                <v-text-field
                  placeholder="Type here . . . . "
                  outlined
                  solo
                  v-model="certification.authorityName"
                ></v-text-field>
              </v-row>
              <v-row>
                <div class="text-h6 font-weight-bold mx-2">
                  Certification issue date
                </div>
              </v-row>
              <v-row>
                <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="certification.awardedDate"
                    label=""
                    placeholder="Select certification awarded date"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                  ></v-text-field>
                </template>
                <v-date-picker
                type="month"
                  v-model="certification.awardedDate"
                  @input="menu = false"
                ></v-date-picker>
              </v-menu>
              </v-row>
              <v-row>
                <v-checkbox
              class="text-h6 font-weight-bold text--primary"
                v-model="certification.nonExpiring"
                label="Is certtificate non expiring ?"
              />
              </v-row>
              <v-row v-if="!certification.nonExpiring">
                <div class="text-h6 font-weight-bold mx-2">
                  Certification valid till
                </div>
              </v-row>
              <v-row>
                <v-menu
                v-if="!certification.nonExpiring"
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="certification.validTill"
                    placeholder="Select certification expiry date"
                    label=""

                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                  ></v-text-field>
                </template>
                <v-date-picker
                type="month"
                  v-model="certification.validTill"
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState } from "vuex";
export default {
  data: () => ({
    dialog: false,
    menu: false,
    menu1:false
  }),
  computed: {
    ...mapState("trainerProfile", {
      certification: "certification",
      loading: "loading",
      profile: "profile"
    })
  },
  props:["id"],
  methods:{
    cb(){
      this.dialog=false,
      this.$refs.certificationFrom.reset()
    },
    createCertification(){
      let updateCertifcaiton =this.certification
      updateCertifcaiton.trainerId=this.profile.trainerId
      this.$store.dispatch('trainerProfile/createCertification',
        { certification:updateCertifcaiton, cb:this.cb }
      )
    },
    closeForm(){
      this.dialog=false;
      this.$refs.certificationFrom.reset()
    },
    getCertificate(){
      this.$store.dispatch('trainerProfile/getACertificaiton',this.id)
    },
    updateCertificate(){
    this.$store.dispatch('trainerProfile/updateCertification',
        { certification:this.certification, cb:this.cb }
      )
    }
  }
};
</script>
<style>
/* .v-dialog {
  border: 3px solid rgb(0, 0, 0);
} */

/* .v-text-field--outlined >>> fieldset {
  border-color: rgba(38, 123, 250, 0.986);
  margin-top: 2px;
} */
</style>
