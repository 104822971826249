<template>
  <v-container fluid>
    <div v-if="awardsList.length > 0">
      <v-list
        v-for="(awardEntity, index) in awardsList"
        :key="index"
        color="#ECF0F3"
      >
        <v-row>
          <v-col>
            <v-card flat width="1400px">
              <v-row>
                <v-col> </v-col>
                <v-spacer> </v-spacer>
                <v-col cols="2">
                  <EditAwardDailog :id="awardEntity.id" />
                </v-col>
              </v-row>
              <v-row class="my-4">
                <v-col cols="3">
                  <div class="text-h7 font-weight-bold mx-3 my-n7 primary--text">
                    Award name
                  </div>
                </v-col>
                <v-col cols="9">
                  <div class="text-h7 font-weight-bold  my-n7">
                    {{ awardEntity.awardName }}
                  </div>
                </v-col>
              </v-row>
              <v-row class="my-4">
                <v-col cols="3">
                  <div class="text-h7 font-weight-bold mx-3 my-n5 primary--text">
                    Award year
                  </div>
                </v-col>
                <v-col cols="9">
                  <div class="text-h7 font-weight-bold  my-n5">
                    {{ awardEntity.awardYear }}
                  </div>
                </v-col>
              </v-row>
              <v-row class="my-4">
                <v-col cols="3">
                  <div class="text-h7 font-weight-bold mx-3 my-n3 primary--text">
                    Award description
                  </div>
                </v-col>
                <v-col cols="9">
                  <div class="text-h7 font-weight-bold  my-n3">
                    {{ awardEntity.awardDescription }}
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="6">
            <!-- <v-card flat height="150px">
          <v-row justify="space-around">
            <div class="text-h7 font-weight-bold my-3">Verified Checkbox</div>
            <v-checkbox
              color="blue"
              value="isVerfied"
              hide-details
            ></v-checkbox>
          </v-row>
        </v-card> -->
          </v-col>
        </v-row>
      </v-list>
    </div>
    <!-- <v-card v-else>
    <h1>No Awards associated with trainer</h1>
  </v-card> -->
  </v-container>
</template>

<script>
import EditAwardDailog from "../../components/AwardsDailog/AwardsDailog.vue";
import { mapState } from "vuex";
export default {
  name: "AwardsCard",
  data() {
    return {
    };
  },
  components: {
    EditAwardDailog
  },
  computed: {
    ...mapState("trainerProfile", {
      loading: "loading",
      profile: "profile",
      awardsList: "awardsList"
    })
  },
  created() {
     this.$store.dispatch("trainerProfile/getAllAwards",this.profile.trainerId);
  }
};
</script>

<style></style>
