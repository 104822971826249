<template>
  <v-container fluid>
    <div v-if="certificationList.length > 0">
      <v-list
        v-for="(certiifcationEntity, index) in certificationList"
        :key="index"
        color="#ECF0F3"
      >
        <v-row>
          <v-col>
            <v-card flat width="1400px">
              <v-row justify="end">
                <v-col> </v-col>
                <v-spacer></v-spacer>
                <v-col cols="2">
                  <EditCertification :id="certiifcationEntity.id" />
                </v-col>
              </v-row>
              <v-row class="my-4">
                <v-col cols="3">
                  <div
                    class="text-h7 font-weight-bold mx-3 my-n7 primary--text"
                  >
                    Certificate Name
                  </div>
                </v-col>
                <v-col cols="9">
                  <div class="text-h7 font-weight-bold  my-n7">
                    {{ certiifcationEntity.certificationName }}
                  </div>
                </v-col>
              </v-row>
              <v-row class="my-4">
                <v-col cols="3">
                  <div
                    class="text-h7 font-weight-bold mx-3 my-n5 primary--text"
                  >
                    Certificate Issuer
                  </div>
                </v-col>
                <v-col cols="9">
                  <div class="text-h7 font-weight-bold my-n5">
                    {{ certiifcationEntity.authorityName }}
                  </div>
                </v-col>
              </v-row>
              <v-row class="my-4">
                <v-col cols="3">
                  <div
                    class="text-h7 font-weight-bold mx-3 my-n4 primary--text"
                  >
                    Certificate Issue Date
                  </div>
                </v-col>
                <v-col cols="9">
                  <div class="text-h7 font-weight-bold  my-n4">
                    {{ certiifcationEntity.awardedDate }}
                  </div>
                </v-col>
              </v-row>
              <v-row v-if="certiifcationEntity.nonExpiring" class="my-4">
                <v-col cols="3">
                  <div
                    class="text-h7 font-weight-bold mx-3 my-n3 primary--text"
                  >
                    Is certification non expiring
                  </div>
                </v-col>
                <v-col cols="9"> 
                  <div class="text-h7 font-weight-bold  my-n3">
                    Yes
                  </div>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col cols="3">
                  <div
                    class="text-h7 font-weight-bold mx-3 my-n3 primary--text"
                  >
                    Certificate Expiry Date
                  </div>
                </v-col>
                <v-col>
                  <div class="text-h7 font-weight-bold  my-n3">
                    {{ certiifcationEntity.validTill }}
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="6">
            <!-- <v-card flat height="150px">
              <v-row justify="space-around">
                <div class="text-h7 font-weight-bold my-3">
                  Verified Checkbox
                </div>
                <v-checkbox
                  color="blue"
                  value="isVerfied"
                  hide-details
                ></v-checkbox>
              </v-row>
            </v-card> -->
          </v-col>
        </v-row>
      </v-list>
    </div>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import EditCertification from "@/components/CertificationsModal/certificationsModal.vue";
export default {
  name: "CertitificationCard",
  data() {
    return {};
  },
  components: {
    EditCertification
  },
  computed: {
    ...mapState("trainerProfile", {
      loading: "loading",
      profile: "profile",
      certificationList: "certificationList"
    })
  },
  created() {
    this.$store.dispatch(
      "trainerProfile/getAllCertifications",
      this.profile.trainerId
    );
  }
};
</script>

<style></style>
