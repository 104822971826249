<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="1000px">
      <template v-slot:activator="{ on }">
        <v-icon color="primary" v-if="id" dark v-on="on" @click="getAward">
          border_color
        </v-icon>
        <!-- <v-btn color="primary" dark >  -->
        <v-icon color="primary" v-on="on" large v-else>
          add
        </v-icon>
        <!-- </v-btn> -->
      </template>
      <v-card>
        <v-card-title>
          <v-row>
            <v-col cols="10">
              <span class="text-h5 font-weight-bold mx-3" v-if="id"
                >Edit Award</span
              >
              <span class="text-h5 font-weight-bold mx-3" v-else
                >Add Award</span
              >
            </v-col>
            <v-col cols="1"> 
              <v-icon color="red" @click="closeForm" dark large dense>
              cancel
            </v-icon>
            </v-col>
            <v-col cols="1"> 
               <v-icon
              color="green"
              @click="createAward"
              v-if="!id"
              dark
              large
              dense
            >
              done
            </v-icon>
            <v-icon color="blue" v-else @click="updateAward" dark large dense>
              done
            </v-icon>

            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="awardFrom">
            <v-container>
              <v-row>
                <div class="text-h6 font-weight-bold mx-2">
                  Award Name <span class="red--text">*</span>
                </div>
              </v-row>
              <v-row>
                <v-text-field
                  placeholder="Type here . . . . "
                  outlined
                  v-model="award.awardName"
                  solo
                ></v-text-field>
              </v-row>
              <v-row>
                <div class="text-h6 font-weight-bold mx-2">
                  Award Description <span class="red--text">*</span>
                </div>
              </v-row>
              <v-row>
                <v-textarea
                  placeholder="Type here . . . . "
                  outlined
                  solo
                  v-model="award.awardDescription"
                ></v-textarea>
              </v-row>
              <v-row>
                <div class="text-h6 font-weight-bold mx-2">
                  Award Year
                </div>
              </v-row>
              <v-row>
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="award.awardYear"
                      label=""
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    type="month"
                    v-model="award.awardYear"
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState } from "vuex";
export default {
  data: () => ({
    dialog: false,
    menu: false,
    menu1: false
  }),
  computed: {
    ...mapState("trainerProfile", {
      award: "award",
      loading: "loading",
      profile: "profile"
    })
  },
  props: ["id"],
  methods: {
    cb() {
      this.dialog = false;
      this.$refs.awardFrom.reset()
    },
    createAward() {
      let updatedAward = this.award;
      updatedAward.trainerId = this.profile.trainerId;
      this.$store.dispatch("trainerProfile/createAward", {
        award: updatedAward,
        cb: this.cb
      });
    },
    getAward(){
        this.$store.dispatch('trainerProfile/getAward',this.id)
    },
    closeForm(){
      this.dialog=false
      this.$refs.awardFrom.reset()
    },
    updateAward(){
       this.$store.dispatch('trainerProfile/updateAward',{ award:this.award, cb:this.cb })
    }
  }
};
</script>
<style>
.v-dialog {
  border: 3px solid rgb(0, 0, 0);
}

.v-text-field--outlined >>> fieldset {
  border-color: rgba(38, 123, 250, 0.986);
  margin-top: 2px;
}
</style>
