<template>
  <v-container fluid>
    <SecondaryNavigation />
    <v-row>
      <v-col col="11">
        <div class="text-h5 font-weight-bold mx-3">
          {{ extraMiles }}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <div class="text-h6 font-weight-bold mx-2">
          Certifications
        </div>
      </v-col>
      <v-col cols="1">
        <!-- <v-icon color="black"  large>
          add -->
        <CertificationsModal />
        <!-- </v-icon> -->
      </v-col>
      <v-spacer></v-spacer>
      <!-- <v-col col="6">
        <div class="text-h6 font-weight-bold mx-2">
          Admin Activity
        </div>
      </v-col> -->
    </v-row>
    <v-row>
      <v-col cols="12" justify="start">
        <CertificationCard />
      </v-col>
    </v-row>
    <v-row justify="start">
      <v-col cols="3">
        <div class="text-h6 font-weight-bold mx-2">
          Awards
        </div>
      </v-col>
      <v-col cols="1">
        <AwardDailog />
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row justify="start">
      <v-col cols="12">
        <AwardCard />
      </v-col>
    </v-row>
    <!-- <v-row>
      <v-col col="5">
        <div class="text-h6 font-weight-bold mx-2">
          Awards
        </div>
      </v-col>
      <v-col>
          <AwardDailog/>
      </v-col>
      <v-col col="6">
        <div class="text-h6 font-weight-bold mx-2">
          Admin Activity
        </div>
      </v-col>
    </v-row> -->

    <v-row>
      <v-col cols="3">
        <div class="text-h6 font-weight-bold mx-2">
          Patent
        </div>
      </v-col>
      <v-col cols="1">
        <PatentDailog />
      </v-col>
        <v-spacer></v-spacer>
      <!-- <v-col>
        <v-icon color="black"  large>
          add
          
        </v-icon>
      </v-col>
      <v-col col="6">
        <div class="text-h6 font-weight-bold mx-2">
          Admin Activity
        </div>
      </v-col> -->
    </v-row>
    <v-row justify="start">
      <v-col cols="12">
        <PatentCard />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <div class="text-h6 font-weight-bold mx-2">
          Publications
        </div>
      </v-col>
      <v-col cols="1">
        <PublicaionsDailog />
      </v-col>
    </v-row>
    <v-row justify="start">
       <v-col cols="12">
        <PublicationCard />
      </v-col>
    </v-row>
    <div :class="true ? activeClass : nonActive">
      Admin comments
    </div>
    <AdminComments :showAddButton="true" type="CERTIFICATIONS" />
  </v-container>
</template>

<script>
import SecondaryNavigation from "@/components/SecondaryNavigation/SecondaryNavigation.vue";
import AdminComments from "../../../components/AdminComments/AdminComments.vue";
import CertificationsModal from "@/components/CertificationsModal/certificationsModal";
import AwardDailog from "@/components/AwardsDailog/AwardsDailog.vue";
import PatentDailog from "@/components/PatentDailog/PatentDailog.vue";
import PublicaionsDailog from "@/components/PublicaitonDailog/publicationDailog.vue";
import CertificationCard from "@/components/CertificationCard/certiificationCard.vue";
import AwardCard from "@/components/AwardsCard/awardCard.vue";
import PatentCard from "@/components/PatentsCard/patentCard.vue";
import PublicationCard from "@/components/PublicaitonsCard/publicationCard.vue";
import { mapState } from "vuex";
export default {
  name: "Certifications",
  components: {
    SecondaryNavigation,
    CertificationsModal,
    AdminComments,
    AwardDailog,
    PatentDailog,
    PublicaionsDailog,
    CertificationCard,
    AwardCard,
    PatentCard,
    PublicationCard
  },
  data() {
    return {
      buttonText: "Save",
      activeClass: "text-h6 font-weight-bold mx-3",
      nonActive: "text-h6 primary--text font-weight-bold",
      extraMiles: "Extra Miles Achieved"
    };
  },
  computed: {
    ...mapState("trainerProfile", {
      loading: "loading",
      profile: "profile",
      extraMilesList: "extraMilesList"
    })
  },
  created() {
    this.$store.dispatch(
      "trainerProfile/getExtraMiles",
      this.profile.trainerId
    );
  }
};
</script>

<style></style>
