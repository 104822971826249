<template>
  <v-container fluid>
    <div v-if="publicationList.length > 0">
      <v-list
        v-for="(publicationEntity, index) in publicationList"
        :key="index"
        color="#ECF0F3"
      >
        <v-row>
          <v-col>
            <v-card flat width="1400px">
              <v-row>
                <v-spacer> </v-spacer>
                <v-col cols="2">
                  <EditPublicationDailog
                    :id="publicationEntity.id"
                  />
                </v-col>
              </v-row>
              <v-row class="my-4">
                <v-col cols="3">
                  <div  class="text-h7 font-weight-bold mx-3 my-n7 primary--text">
                    Publication title
                  </div>
                </v-col>
                <v-col cols="9">
                  <div class="text-h7 font-weight-bold  my-n7">
                    {{ publicationEntity.publicationTitle }}
                  </div>
                </v-col>
              </v-row>
              <v-row class="my-4">
                <v-col cols="3">
                  <div class="text-h7 font-weight-bold mx-3 my-n5 primary--text">
                    Publication date
                  </div>
                </v-col>
                <v-col cols="9">
                  <div class="text-h7 font-weight-bold  my-n5">
                    {{ publicationEntity.publicationDate }}
                  </div>
                </v-col>
              </v-row>
              <v-row class="my-4">
                <v-col cols="3">
                  <div class="text-h7 font-weight-bold mx-3 my-n3 primary--text">
                    Publication type
                  </div>
                </v-col>
                <v-col cols="9">
                  <div class="text-h7 font-weight-bold  my-n3">
                    {{ publicationEntity.publicationType }}
                  </div>
                </v-col>
              </v-row>
              <v-row class="my-4">
                <v-col cols="3">
                    <div class="text-h7 font-weight-bold mx-3 primary--text">
                      Publisher Name
              </div>
                </v-col>
                <v-col>
                   <div class="text-h7 font-weight-bold">
                {{ publicationEntity.publishedName }}
              </div>
                </v-col>
              </v-row>
             
            </v-card>
          </v-col>
          <v-col cols="6">
            <!-- <v-card flat height="150px">
              <v-row justify="space-around">
                <div class="text-h7 font-weight-bold my-3">
                  Verified Checkbox
                </div>
                <v-checkbox
                  color="blue"
                  value="isVerfied"
                  hide-details
                ></v-checkbox>
              </v-row>
            </v-card> -->
          </v-col>
        </v-row>
      </v-list>
    </div>
    <!-- <v-card v-else>
      <h1>No Publication associated with trainer</h1>
    </v-card> -->
  </v-container>
</template>

<script>
import EditPublicationDailog from "../../components/PublicaitonDailog/publicationDailog.vue";
import { mapState } from "vuex";
export default {
  name: "PublicationCard",
  components: {
    EditPublicationDailog
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState("trainerProfile", {
      loading: "loading",
      profile: "profile",
      publicationList: "publicationList"
    })
  },
  created() {
    this.$store.dispatch("trainerProfile/getAllPublications",this.profile.trainerId);
   }
};
</script>

<style></style>
